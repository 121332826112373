<template>
  <div>
    <v-container>
      <v-spacer></v-spacer>
      <v-row>
        <v-col :cols=3></v-col>
        <v-col :cols="6">
          <v-img
            src="../img/logoDemo1png.png"
            height="150"
            width="300"
          ></v-img>
          <h3 class="mt-5">Reset Password</h3>
          <v-form>
            <v-text-field outline 
              label="email" type="email" v-model="userEmail"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field outline hide-details label="New Password" type="password" v-model="userPassword"
            :rules="passwordRules"  
            ></v-text-field>
            <v-text-field outline hide-details label="Confirm New Password" type="password" v-model="userPasswordConfirmed" 
            :rules="passwordRules" 
            ></v-text-field>
          </v-form>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mt-5" @click="SubmitForm">Reset Password</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AuthService from '@/api-services/auth.service.js'

export default {
  name: 'ResetPassword',
  components: {
   
  },
  data () {
    return {
      userEmail: "",
      userPassword: "",
      userPasswordConfirmed: "",
      checkPasswords: false,
      passwordRules: [
         v => (/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v)) 
         || `Please enter a password that contains at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character and is at least 8 characters long.`
       ],
      emailRules: [
         v => (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)) ||
              `Please enter a valid email`
       ],
    }
  },
  mounted () {
    
  },
  methods: {
    async SubmitForm() {
      this.CheckPasswordsMatch()
      
      await AuthService.resetPassword(this.userEmail, this.userPassword, this.$route.params.code)
      this.checkPasswords = true
      this.$router.push('/')
    },
    CheckPasswordsMatch() {
      let match = false
      if(this.userPassword === this.userPasswordConfirmed) {
        match = true
      }
      return match
    }
  },
  watch: {
    userPasswordConfirmed(){
      if(this.checkPasswords) {
        const match = this.CheckPasswordsMatch()
        if(!match) {
          //need form validation here
          console.log("do not match")
        }
      }
    }
  }
    
}
</script>

<style scoped>
</style>
